export namespace MethodID {
  export const LASER = 1;
  export const PLASMA = 2;
  export const WATERJET = 3;
  export const FDM = 4;
  export const SLS = 5;
  export const SLA = 6;
  export const METAL = 7;
  export const MJP = 8;
  export const MILLING = 9;
  export const LATHE = 10;
}

export namespace FeeMethod {
  export const SMART_PRICE = 'smart_price';
  export const MACHINE_TIME_MATERIALS = 'machine_time_materials';
  export const PRICED_PROCESS = 'priced_process';
  export const MATERIAL_PRICE = 'material_price';
  export const AUTOMATED_RFQ = 'automated_rfq';
  export const AUTOMATED_PRICING = 'automated_pricing';
}

export interface IMethod {
  id: number;
  name: string;
  icon: string;
  type: string;
  layer_heights: number[];
  is_infill_percentage_required: boolean;
}

export namespace MethodUtils {
  export const getLayerHeights = (methodId: number) => {
    switch (methodId) {
      case MethodID.FDM:
        return [0.1, 0.2, 0.3];
      case MethodID.SLA:
        return [0.025, 0.035, 0.05, 0.1];
      case MethodID.METAL:
      case MethodID.SLS:
        return [0.05, 0.1];
      case MethodID.MJP:
        return [0.016, 0.027, 0.032];
      default:
        return [];
    }
  };
}
